import { FC } from 'react';
import { Grid } from '@mui/material';
// component
import RadioGroupController from 'controller/RadioGroupController';
// constants
import { VEGAN_MAPPED, VEGAN_TEXT } from 'constants/index';

const VeganTypeRadioGroup: FC = () => (
  <Grid item xs={12} xl={12}>
    <RadioGroupController name="veganType" title={VEGAN_TEXT} options={VEGAN_MAPPED} />
  </Grid>
);

export default VeganTypeRadioGroup;
