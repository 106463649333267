import { Grid } from '@mui/material';
import { FC, Fragment } from 'react';
// components
import ExcipientFields from '../../../Excipients';
import RadioGroupController from 'controller/RadioGroupController';
import TabletTypeRadioGroup from 'components/common/radioGroups/TabletType';
import TabletCoatingRadioGroup from 'components/common/radioGroups/TabletCoating';
// constants, graphQL
import {
  TABLET_TEXT,
  TABLET_COLOR_TEXT,
  TABLET_COATING_TEXT,
  SINGLE_TABLET_COLOR_MAPPED,
} from 'constants/index';
import { ProductTypes } from 'generated/graphql';

const TabletInCap: FC = () => {
  return (
    <Fragment>
      <Grid item xs={12} xl={12}>
        <TabletTypeRadioGroup isRequired name="tabletType" title={TABLET_TEXT} />
      </Grid>
      <Grid item xs={12} xl={12}>
        <TabletCoatingRadioGroup
          name="tabletCoating"
          title={TABLET_COATING_TEXT}
          productType={ProductTypes.Tablet}
        />
      </Grid>
      <Grid item xs={12} xl={12}>
        <RadioGroupController
          isRequired
          name="coatingColor"
          title={TABLET_COLOR_TEXT}
          options={SINGLE_TABLET_COLOR_MAPPED}
        />
      </Grid>

      <ExcipientFields />
    </Fragment>
  );
};

export default TabletInCap;
