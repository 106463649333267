import { FC, Fragment, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Button, ClickAwayListener, MenuItem } from '@mui/material';
import { KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon } from '@mui/icons-material';
// component
import Detail from './components';
import { Alert } from 'components/common/Alert';
import FactSheetCard from './components/FactSheetCard';
// constants, styles, interface, graphql, helper
import {
  VIEW_TEXT,
  EXPORT_TEXT,
  HTTP_STATUS,
  SPEC_SHEET_TEXT,
  FACT_SHEET_TEXT,
  EXCEL_SHEET_TEXT,
  DUPLICATE_TEXT,
  FORMULA_DETAILS_TEXT,
  SHEET_NOT_FOUND_TEXT,
  DUPLICATE_FORMULA_ROUTE,
  CSV_SHEET_TEXT,
} from 'constants/index';
import { ParamType } from 'interfaces';
import { downloadSheet } from 'lib/helper';
import { ExportMenuStyles, ExportBoxStyle, flexCenterBetween } from 'styles/commonComponentStyle';
import {
  AttachmentTitle,
  AttachmentType,
  useExportFormulaLazyQuery,
  useGetAttachmentByTypeLazyQuery,
} from 'generated/graphql';

const FormulaDetail: FC = () => {
  const { id } = useParams<ParamType>();
  const navigate = useNavigate();
  // local states
  const [open, setOpen] = useState(false);
  const [openFactSheet, setOpenFactSheet] = useState(false);
  const [sheetUrl, setSheetUrl] = useState<string>('');

  const [getAttachment, { loading: loadingOtherExport }] = useGetAttachmentByTypeLazyQuery({
    onCompleted: (data) => {
      const { getAttachmentByType } = data || {};
      const { attachment, response } = getAttachmentByType || {};
      const { status } = response || {};
      const { attachmentUrl } = attachment || {};
      if (status === HTTP_STATUS.SUCCESS && attachmentUrl) {
        setSheetUrl(attachmentUrl);
        if (!openFactSheet) {
          downloadSheet(attachmentUrl);
        }
      } else {
        Alert.warning(SHEET_NOT_FOUND_TEXT);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getExportFormula, { loading: loadingExportFormula }] = useExportFormulaLazyQuery({
    onCompleted: (data) => {
      const { exportFormula } = data || {};
      const { attachment, response } = exportFormula || {};
      const { attachmentUrl } = attachment || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS && attachmentUrl) {
        downloadSheet(attachmentUrl);
      } else {
        Alert.warning(SHEET_NOT_FOUND_TEXT);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const viewFactSheet = async () => {
    setOpenFactSheet(!openFactSheet);
    if (id) {
      await getAttachment({
        variables: {
          getAttachmentByTypeInput: {
            typeId: id,
            type: AttachmentType.Formula,
            title: AttachmentTitle.FactSheet,
          },
        },
      });
    }
  };

  const fetchExcelSheet = async () => {
    if (id) {
      await getAttachment({
        variables: {
          getAttachmentByTypeInput: {
            typeId: id,
            type: AttachmentType.Formula,
            title: AttachmentTitle.ExcelSheet,
          },
        },
      });
    }
  };

  const fetchSpecSheet = async () => {
    if (id) {
      await getAttachment({
        variables: {
          getAttachmentByTypeInput: {
            typeId: id,
            type: AttachmentType.Formula,
            title: AttachmentTitle.SpecSheet,
          },
        },
      });
    }
  };

  const fetchFactSheet = async () => {
    if (id) {
      await getAttachment({
        variables: {
          getAttachmentByTypeInput: {
            typeId: id,
            type: AttachmentType.Formula,
            title: AttachmentTitle.FactSheet,
          },
        },
      });
    }
  };

  const fetchCsvSheet = async () => {
    // setOpenFactSheet(!openFactSheet);
    if (id) {
      await getExportFormula({
        variables: {
          getFormulaInput: {
            id,
          },
        },
      });
    }
  };

  const loading = loadingExportFormula || loadingOtherExport;

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{FORMULA_DETAILS_TEXT}</Typography>
        <Box display="flex">
          <Button
            variant="outlined"
            size="small"
            sx={{ mr: 2 }}
            onClick={() => navigate(`${DUPLICATE_FORMULA_ROUTE}/${id}`)}>
            {DUPLICATE_TEXT}
          </Button>
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Box position="relative">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setOpen(!open)}
                endIcon={<KeyboardArrowDownRoundedIcon />}>
                {EXPORT_TEXT}
              </Button>

              {open ? (
                <Box sx={ExportBoxStyle} p={1} minWidth={165}>
                  <MenuItem sx={ExportMenuStyles} onClick={fetchFactSheet}>
                    {EXPORT_TEXT} {FACT_SHEET_TEXT}
                  </MenuItem>

                  <MenuItem sx={ExportMenuStyles} onClick={fetchExcelSheet}>
                    {EXPORT_TEXT} {EXCEL_SHEET_TEXT}
                  </MenuItem>

                  <MenuItem sx={ExportMenuStyles} onClick={fetchSpecSheet}>
                    {EXPORT_TEXT} {SPEC_SHEET_TEXT}
                  </MenuItem>

                  <MenuItem sx={ExportMenuStyles} onClick={fetchCsvSheet}>
                    {EXPORT_TEXT} {CSV_SHEET_TEXT}
                  </MenuItem>
                </Box>
              ) : (
                <Fragment />
              )}
            </Box>
          </ClickAwayListener>
          <Button variant="contained" size="small" sx={{ ml: 2 }} onClick={viewFactSheet}>
            {VIEW_TEXT} {FACT_SHEET_TEXT}
          </Button>
        </Box>
      </Box>
      <Detail />
      <FactSheetCard
        loading={loading}
        sheetUrl={sheetUrl}
        open={openFactSheet}
        onClose={() => setOpenFactSheet(!openFactSheet)}
      />
    </Fragment>
  );
};

export default FormulaDetail;
